import { NativeChannel } from "./channel";

export class NativeKitLocalStorage {
    public static async getItem<T = any>(key: string): Promise<T | null> {
        const _response = await this._sendEvent({
            action: 'getItem',
            key,
        });
        if (_response.value) {
            return JSON.parse(_response.value);
        }
        return null;
    }

    public static async setItem<T = any>(key: string, value: T): Promise<void> {
        await this._sendEvent({
            action: 'setItem',
            key,
            value: JSON.stringify(value),
        });
    }

    public static async removeItem(key: string): Promise<void> {
        await this._sendEvent({
            action: 'removeItem',
            key,
        });
    }

    private static _sendEvent(options: NativeKitLocalStorage.Options): Promise<NativeKitLocalStorage.RawResponse> {
        return NativeChannel.sendData<NativeKitLocalStorage.RawResponse>('nativekit_localstorage', options);
    }
}

export namespace NativeKitLocalStorage {
    export interface Options {
        action: string;
        key: string;
        value?: string;
    }
    export interface RawResponse {
        key: string;
        value: string;
    }
}