import React from 'react';
import { Floppy } from '../../game/Game';
import { NativeKit } from '../../nativekit/nativekit';

export default class FeedView extends React.Component<FeedView.Props, FeedView.State> {
    state = { highScore: null };

    private async _loadScore() {
        // const _highScore = (await NativeKit.LocalStorage.getItem<number>('high-score')) ?? 0;
        const _highScore = (Floppy.storage.getHighScore()) ?? 0;
        this.setState({ highScore: _highScore });
    }

    componentDidMount() {
        this._loadScore();
        // TEMP: until trigger for feed reload
        setInterval(this._loadScore, 1000);
    }

    render() {
        return (
            <div style={{
                height: '100vh',
                fontSize: 18,
                fontFamily: 'Silkscreen',
                backgroundColor: '#4ec0ca',
                backgroundImage: 'url(/assets/sky.png)',
                backgroundRepeat: 'repeat-x',
                backgroundPosition: 'bottom center',
            }} onClick={() => NativeKit.SystemCallback.expand()}>
                <img
                    src="https://images.ctfassets.net/9ao5ytx7vjwk/4nkk89p9S7P7Hd4kMEEs2a/97ad8918d7d691bdd25b146b79e64204/skanda-vishwanath-flappy-bird-02-removebg-preview.png?h=390"
                    style={{
                        width: '100%',
                    }}
                /><br />
                {this.state.highScore == null && (<div style={{
                    color: '#fff',
                    textAlign: 'center',
                    marginTop: 27,
                }}>
                    loading...
                </div>)}
                {this.state.highScore != null &&
                    this.state.highScore === 0 && (<div style={{
                        color: '#fff',
                        textAlign: 'center',
                        marginTop: 27,
                    }}>
                        No high score yet! Tap to play
                    </div>)}
                {this.state.highScore != null &&
                    this.state.highScore > 0 && (<div style={{
                        color: '#fff',
                        textAlign: 'center',
                        marginTop: 27,
                    }}>
                        Current high score: <br />
                        <span style={{ fontSize: 22 }}>{this.state.highScore}</span> <br /><br />
                        <span style={{ color: '#28a745' }}>you have the high score!</span>
                    </div>)}
            </div>
        );
    }
}

export namespace FeedView {
    export interface Props {
    }
    export interface State {
        highScore: number | null;
    }
}