import { v4 } from "uuid";

declare const window: any;

window.NativeKitResolvers = {};

export class NativeChannel {
    public static sendData<T = void>(type: string, data: any): Promise<T> {
        const _messageId = v4();
        return new Promise((resolve) => {
            window.NativeKitChannel.postMessage(JSON.stringify({
                id: _messageId,
                type,
                data,
            }));
            window.NativeKitResolvers[_messageId] = (data: any) => {
                resolve(data);
                delete window.NativeKitResolvers[_messageId];
            };
        });
    }
}