import { NativeKitSystemCallback } from "./callbacks/system-callback";
import NativeKitHapticButton from "./components/HapticButton";
import NativeKitTextFieldComponent from "./components/TextField";
import { NativeKitAds } from "./lib/ads";
import { NativeKitDialogs } from "./lib/dialogs";
import { NativeKitHaptic } from "./lib/haptic";
import { NativeKitLocalStorage } from "./lib/local-storage";
import { NativeKitPurchases } from "./lib/purchases";
import { NativeKitTextField } from "./lib/text-field";

export class NativeKit {

}

export namespace NativeKit {
    export type Haptic = NativeKitHaptic;
    export const Haptic = NativeKitHaptic;
    export type Ads = NativeKitAds;
    export const Ads = NativeKitAds;
    export type Dialogs = NativeKitDialogs;
    export const Dialogs = NativeKitDialogs;
    export type LocalStorage = NativeKitLocalStorage;
    export const LocalStorage = NativeKitLocalStorage;
    export type SystemCallback = NativeKitSystemCallback;
    export const SystemCallback = NativeKitSystemCallback;
    export type Purchases = NativeKitPurchases;
    export const Purchases = NativeKitPurchases;
    export type TextField = NativeKitTextField;
    export const TextField = NativeKitTextField;
    // export namespace Components {
    //     export type HapticButton = NativeKitHapticButton;
    //     export const HapticButton = NativeKitHapticButton;
    //     export type TextField = NativeKitTextFieldComponent;
    //     export const TextField = NativeKitTextFieldComponent;
    // }
}

export type HapticButton = NativeKitHapticButton;
export const HapticButton = NativeKitHapticButton;
export type TextField = NativeKitTextFieldComponent;
export const TextField = NativeKitTextFieldComponent;