import { v4 } from "uuid";

declare const JC_Purchases: any;
declare const window: any;

window.resolveFunctions = {};

export class NativeKitPurchases {
    public static purchase(data: NativeKitPurchases.PurchaseData): Promise<void> {
        const _id = v4();
        JC_Purchases.postMessage(JSON.stringify({
            id: _id,
            data,
        }));
        return new Promise((resolve) => {
            window.resolveFunctions[_id] = resolve.bind(this);
        });
    }
}

export namespace NativeKitPurchases {
    export interface PurchaseData {
        name: string;
        description: string;
        amount: number;
    }
}