import React from "react";
import { Floppy } from "../../../game/Game";

export class Game extends React.Component {

    componentDidMount() {
        Floppy.initialize();
    }

    render() {
        return (
            <div id="gamecontainer">
                <div id="gamescreen">
                    <div id="sky" className="animated">
                        <div id="flyarea">
                            <div id="debug-state"></div>
                            <div id="debug-logs"></div>
                            <div id="ceiling" className="animated"></div>
                            <div id="player" className="bird animated"></div>

                            <div style={{
                                position: 'fixed',
                                top: 64,
                                width: '100vw',
                                display: 'flex',
                                justifyContent: 'center',
                                zIndex: 100,
                            }}>
                                <div id="bigscore"></div>
                            </div>

                            <div className="screen-centered">
                                <div id="splash"></div>
                            </div>
                            <div className="screen-centered">
                                <div id="scoreboard">
                                    <div id="medal"></div>
                                    <div id="currentscore"></div>
                                    <div id="highscore"></div>
                                    <div id="replay"><img src="/assets/replay.png" alt="replay" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="land" className="animated"></div>
                    <div id="debug"></div>
                </div>
            </div>
        );
    }
}
