import React from 'react';
import logo from '../logo.svg';
import { NativeKit, HapticButton } from '../nativekit/nativekit';

export default class MainView extends React.Component<MainView.Props, MainView.State> {
    state = { isPurchased: false };

    render() {
        return (
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.4)',
                backdropFilter: 'blur(10px)',
                borderRadius: 9,
                padding: '18px 36px',
                color: '#fff',
                fontSize: 18,
                fontWeight: 700,
            }}>
                <img src={this.state.isPurchased ? 'https://bit.ly/3bLgzyb' : logo} className="App-logo" alt="logo" /><br />
                this app is built in react.<br /><br />
                <HapticButton
                    onClick={async () => {
                        await NativeKit.Purchases.purchase({
                            name: 'Boost',
                            description: 'boost your sendit message',
                            amount: 399,
                        });
                        this.setState({ isPurchased: true });
                    }}
                >
                    Boost Message
                </HapticButton>
            </div>
        );
    }
}

export namespace MainView {
    export interface Props {
    }
    export interface State {
        isPurchased: boolean;
    }
}