import { NativeChannel } from "./channel";

export class NativeKitAds {
    public static async load(type: NativeKitAds.Type): Promise<boolean> {
        return (await this._sendEvent({
            action: NativeKitAds.Action.Load,
            type,
        })).result;
    }
    public static async show(type: NativeKitAds.Type): Promise<boolean> {
        return (await this._sendEvent({
            action: NativeKitAds.Action.Show,
            type,
        })).result;
    }

    private static _sendEvent(options: NativeKitAds.Options): Promise<NativeKitAds.RawResponse> {
        return NativeChannel.sendData<NativeKitAds.RawResponse>('nativekit_ads', options);
    }
}

export namespace NativeKitAds {
    export enum Action {
        Load = 'load',
        Show = 'show',
    }
    export enum Type {
        Rewarded = 'rewarded',
    }
    export interface Options {
        action: NativeKitAds.Action;
        type: NativeKitAds.Type;
    }
    export interface RawResponse {
        result: boolean;
    }
}