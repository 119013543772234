import { NativeChannel } from "./channel";

export class NativeKitHaptic {
    public static async selectionClick(): Promise<void> {
        await this._sendHaptic('selectionClick');
    }

    public static async light(): Promise<void> {
        await this._sendHaptic('light');
    }

    public static async medium(): Promise<void> {
        await this._sendHaptic('medium');
    }

    public static async heavy(): Promise<void> {
        await this._sendHaptic('heavy');
    }

    public static async vibrate(): Promise<void> {
        await this._sendHaptic('vibrate');
    }

    private static _sendHaptic(intensity: string): Promise<void> {
        return NativeChannel.sendData('nativekit_haptic', {
            intensity,
        });
    }
}