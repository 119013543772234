import { NativeChannel } from "./channel";

export class NativeKitDialogs {
    public static async alert(message: string): Promise<void> {
        await this._sendEvent({
            type: 'alert',
            body: message,
        });
    }

    private static _sendEvent(options: NativeKitDialogs.Options): Promise<NativeKitDialogs.RawResponse> {
        return NativeChannel.sendData<NativeKitDialogs.RawResponse>('nativekit_dialogs', options);
    }
}

export namespace NativeKitDialogs {
    export interface Options {
        type: string;
        body: string;
    }
    export interface RawResponse {

    }
}