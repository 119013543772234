import React from 'react';
import { NativeKitTextField } from '../lib/text-field';

export default class NativeKitTextFieldComponent extends React.Component<NativeKitTextFieldComponent.Props, NativeKitTextFieldComponent.State> {
    state = { value: '', hasFocus: false };

    componentDidMount() {
        this.setState({ value: this.props.value });
        document.addEventListener('NativeKitTextFieldUpdate', (e: any) => {
            if (this.state.hasFocus) {
                this.setState({ value: e.detail.value });
            }
        });
    }

    private _onFocus(e: React.FocusEvent<HTMLDivElement, Element>): void {
        this.setState({ hasFocus: true });
        NativeKitTextField.focus({
            value: this.state.value,
        });
        for (let i = 0; i < 10; ++i) {
            setTimeout(() => e.target.scrollIntoView({
                behavior: 'smooth'
            }), 50);
        }
    }

    private _onBlur(): void {
        this.setState({ hasFocus: false });
        NativeKitTextField.blur({
            value: this.state.value,
        });
    }

    render() {
        return (
            <div
                tabIndex={1}
                onFocus={(e) => this._onFocus(e)}
                onBlur={() => this._onBlur()}
                style={{
                    ...this.props.style,
                    overflow: 'hidden',
                }}>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                    {this.state.value.length === 0 && (
                        <span style={{
                            fontSize: this.props.style?.fontSize,
                            fontWeight: this.props.style?.fontWeight,
                            color: '#aaa',
                        }}>{this.props.placeholder}</span>
                    )}
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    whiteSpace: 'nowrap',
                }}>
                    {this.state.value}
                    {this.state.hasFocus && (<span style={{ animation: '1s blink infinite' }}>│</span>)}
                </div>
                <span style={{ opacity: 0 }}>line height</span>
            </div>
        );
    }
}

export namespace NativeKitTextFieldComponent {
    export interface Props {
        placeholder?: string;
        style?: React.CSSProperties;
        value: string;
        onClick?: () => void;
    }
    export interface State {
        value: string;
        hasFocus: boolean;
    }
}